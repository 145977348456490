<template lang="pug">
  v-container(fluid)
    v-row(justify="center")
      v-col(cols="12" sm="12" md="6" lg="5" xl="5")
        v-card
          v-card-title(class="primary white--text") Login - Área do parceiro
          TopLoading

          v-card-text
            v-form(ref="form" lazy-validation v-model="valid" class="mt-4")
              v-row(dense)
                v-col
                  v-text-field(outlined id="email" v-model="email" :rules="[rules.email, rules.required]" name="email" type="email" label="email" data-vv-as="email" autocomplete="off")
              v-row(dense)
                v-col
                  v-text-field(outlined id="password" v-model="password" :rules="[rules.required]" name="password" type="password" label="senha" data-vv-as="password" autocomplete="off" :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (value = !value)"
                  :type="value ? 'password' : 'text'" v-on:keyup.enter="submit")

            v-card-actions
              v-row(dense)
                v-col
                  v-btn(@click="submit" depressed class="white--text" block color="buttons" :loading="showBtnLoading") Entrar
                v-col
                  v-btn(:href="`${ambientUrl}forgot`" target="_blank" block text class="text-lowercase accent--text") Esqueci a senha
      ErrorMessage
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import TopLoading from '@/components/core/TopLoading.vue'

import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3' // recaptcha no Login
Vue.use(VueReCaptcha, { siteKey: '6LfaK4IaAAAAAFyL_mC2cTeLgkcdpCOUVJn8QkOr', loaderOptions: { autoHideBadge: true } })

  export default{
    name: 'Login',

    components: {
      TopLoading
    },

    data: () => ({
      ambientUrl: process.env.VUE_APP_SHARE_URL,
      value: true,
      valid: true,
      captchaToken: '',
      rules: {
        required: value => !!value || 'Campo obrigatório',
        //email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Digite um e-mail válido'
        email: v => !v || /.+@.+\..+/.test(v) || 'Digite um e-mail válido'
      },
      email: '',
      password: ''
    }),
    computed: {
      ...mapGetters(['showBtnLoading'])
    },
    methods: {
      ...mapActions(['userLogin']),
      async submit(){
        if(this.$refs.form.validate()){

          await this.$recaptchaLoaded()
          this.captchaToken = await this.$recaptcha('login')

          await this.userLogin({
            email: this.email,
            password: this.password,
            token: this.captchaToken+'HGHCkxRqKsP2TN4G'
          })
        }
      }
    }
  }
</script>
